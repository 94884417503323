import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout3 from '../components/layout2';

import Sdgs from '../containers/sdgs';

import Styles from './chirisogo.module.css';


const HomePage = (props: any) => {
  const { data } = props;

  return (
    <Layout3>

1223 さまざまな交通網の発達
122-5 観光のグローバル化
2	2	4	2	発展途上国と先進国の人口問題
2	2	6	1	世界の都市の発展
2	2	6	2	発展途上国と先進国の都市
3	2	1	1	地理的な課題と地域調査

<div>高校地理総合　目次</div>

<div className={Styles.l_toc}>
    <ol>
        <li><a href="#chapter12">１章２節</a>
            <ol className={Styles.child_ol}>
                <li><a href="#schapter1223">2-3 さまざまな交通網の発達</a></li>
                <li><a href="#schapter1225">2-5 観光のグローバル化</a></li>
            </ol>
        </li>
        <li><a href="#chapter13">２章２節</a>
            <ol className={Styles.child_ol}>
                <li><a href="#schapter2242">4-2 発展途上国と先進国の人口問題</a></li>
                <li><a href="#schapter2261">6-1 世界の都市の発展</a></li>
                <li><a href="#schapter2262">6-2 発展途上国と先進国の都市</a></li>
            </ol>
        </li>
        <li><a href="#chapter14">３章２節</a>
            <ol className={Styles.child_ol}>
                <li><a href="#schapter3211">1-1 地理的な課題と地域調査</a></li>
            </ol>
        </li>
    </ol>
</div>

<div id="schapter1214" className={Styles.linkdiv}></div>
<div id="schapter1256"></div>
<div>1-4 日本の農業の現状と課題</div>
<div>本文<br/>本文<br/>本文<br/>本文<br/><Link to="/tags/日本の農業の現状と課題">データ選択画面へ</Link></div>
<div id="schapter1312"></div>
<div>5-6 日本の工業</div>
<div>本文<br/>本文<br/>本文<br/>本文<br/><Link to="/tags/日本の工業">データ選択画面へ</Link></div>
<div id="schapter1321"></div>
<div>1-2 消費行動の変化と商業</div>
<div>本文<br/>本文<br/>本文<br/>本文<br/><Link to="/tags/消費行動の変化と商業">データ選択画面へ</Link></div>
<div id="schapter1422"></div>
<div>2-1 交通網の発達</div>
<div>本文<br/>本文<br/>本文<br/>本文<br/><Link to="/tags/交通網の発達">データ選択画面へ</Link></div>
<div id="schapter1433"></div>
<div>2-2 日本の人口問題</div>
<div>本文<br/>本文<br/>本文<br/>本文<br/><Link to="/tags/日本の人口問題">データ選択画面へ</Link></div>
<div id="schapter1434"></div>
<div>3-3 都市の成立と機能・形態</div>
<div>本文<br/>本文<br/>本文<br/>本文<br/><Link to="/tags/都市の成立と機能・形態">データ選択画面へ</Link></div>
<div id="schapter1443"></div>
<div>3-4 都市圏の拡大と都市の構造</div>
<div>本文<br/>本文<br/>本文<br/>本文<br/><Link to="/tags/都市圏の拡大と都市の構造">データ選択画面へ</Link></div>
<div id="schapter2524"></div>
<div>4-3 日本の都市・居住問題</div>
<div>本文<br/>本文<br/>本文<br/>本文<br/><Link to="/tags/日本の都市・居住問題">データ選択画面へ</Link></div>
<div>2-4 多文化の共生に向けた取り組み</div>
<div>本文<br/>本文<br/>本文<br/>本文<br/><Link to="/tags/多文化の共生に向けた取り組み">データ選択画面へ</Link></div>

    </Layout3>
  );
};

export default HomePage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
